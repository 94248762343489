import React from "react";
import '../helpers/suppress-install-prompt';
import Layout from "../components/Layout";
import Seo from "../components/seo";
import Hero from "../components/Restart/Hero";
import Restart1 from "../components/Restart/Restart1";
import Restart2 from "../components/Restart/Restart2";
import Restart3 from "../components/Restart/Restart3";
import Restart4 from "../components/Restart/Restart4";
import Restart5 from "../components/Restart/Restart5";
import Restart6 from "../components/Restart/Restart6";
import Restart7 from "../components/Restart/Restart7";
import Restart8 from "../components/Restart/Restart8";
import Restart9 from "../components/Restart/Restart9";
import Restart10 from "../components/Restart/Restart10";

import { graphql } from "gatsby";

const Page = ({ data }) => (
    <Layout page="restart" showMenu={true}>
        <Seo
            title="Restart"
            description="Wymarzona sylwetka, dobre samopoczucie i energia. Dzięki dożywotniemu dostępowi do diety i treningów już zawsze będą one Twoje! Zagwarantuj sobie nieograniczony czasowo dostęp do wszystkich funkcji Diet & Training by Ann."
        >
            <meta name="robots" content="noindex,nofollow" />
        </Seo>
        <Hero hero={data.hero.childImageSharp.gatsbyImageData} />
        <Restart1
            ania1={data.ania1.childImageSharp.gatsbyImageData}
            ania2={data.ania2.childImageSharp.gatsbyImageData}
            ania3={data.ania3.childImageSharp.gatsbyImageData}
            ania4={data.ania4.childImageSharp.gatsbyImageData}
        />
        <Restart2
            restart2Bg={data.restart2Bg.childImageSharp.gatsbyImageData}
        />
        <Restart3
            fourDiet={data.fourDiet.childImageSharp.gatsbyImageData}
            biggerBase={data.biggerBase.childImageSharp.gatsbyImageData}
            newPrograms={data.newPrograms.childImageSharp.gatsbyImageData}
            sign={data.sign.childImageSharp.gatsbyImageData}
        />
        <Restart4
            people1={data.people1.childImageSharp.gatsbyImageData}
            people2={data.people2.childImageSharp.gatsbyImageData}
            people3={data.people3.childImageSharp.gatsbyImageData}
            people4={data.people4.childImageSharp.gatsbyImageData}
            people5={data.people5.childImageSharp.gatsbyImageData}
            people6={data.people6.childImageSharp.gatsbyImageData}
            people7={data.people7.childImageSharp.gatsbyImageData}
            people8={data.people8.childImageSharp.gatsbyImageData}
            people9={data.people9.childImageSharp.gatsbyImageData}
            people10={data.people10.childImageSharp.gatsbyImageData}
            people11={data.people11.childImageSharp.gatsbyImageData}
            people12={data.people12.childImageSharp.gatsbyImageData}
            people13={data.people13.childImageSharp.gatsbyImageData}
            people14={data.people14.childImageSharp.gatsbyImageData}
            people15={data.people15.childImageSharp.gatsbyImageData}
            people16={data.people16.childImageSharp.gatsbyImageData}
            people17={data.people17.childImageSharp.gatsbyImageData}
            people18={data.people18.childImageSharp.gatsbyImageData}
            people19={data.people19.childImageSharp.gatsbyImageData}
            people20={data.people20.childImageSharp.gatsbyImageData}
            people21={data.people21.childImageSharp.gatsbyImageData}
            people22={data.people22.childImageSharp.gatsbyImageData}
            people23={data.people23.childImageSharp.gatsbyImageData}
            people24={data.people24.childImageSharp.gatsbyImageData}
            people25={data.people25.childImageSharp.gatsbyImageData}
            people26={data.people26.childImageSharp.gatsbyImageData}
            people27={data.people27.childImageSharp.gatsbyImageData}
            people28={data.people28.childImageSharp.gatsbyImageData}
        />
        <Restart5
            restart2Bg={data.ania6.childImageSharp.gatsbyImageData}
            rightBg={true}
        />
        <Restart6 />
        <Restart7 />
        <Restart8
            socialProof1={data.socialProof1.childImageSharp.gatsbyImageData}
            socialProof2={data.socialProof2.childImageSharp.gatsbyImageData}
            socialProof3={data.socialProof3.childImageSharp.gatsbyImageData}
            socialProof4={data.socialProof4.childImageSharp.gatsbyImageData}
            socialProof5={data.socialProof5.childImageSharp.gatsbyImageData}
            socialProof6={data.socialProof6.childImageSharp.gatsbyImageData}
            socialProof7={data.socialProof7.childImageSharp.gatsbyImageData}
            socialProof8={data.socialProof8.childImageSharp.gatsbyImageData}
            socialProof9={data.socialProof9.childImageSharp.gatsbyImageData}
            socialProof10={data.socialProof10.childImageSharp.gatsbyImageData}
            socialProof11={data.socialProof11.childImageSharp.gatsbyImageData}
            socialProof12={data.socialProof12.childImageSharp.gatsbyImageData}
        />
        <Restart9 />
        <Restart10 />
        <Restart5
            restart2Bg={data.ania5.childImageSharp.gatsbyImageData}
        />
    </Layout>
);

export default Page;

export const query = graphql`
    query {
        hero: file(relativePath: { eq: "restart/hero.jpg" }) {
            ...fluidImage1920
        }

        ania1: file(relativePath: { eq: "restart/ania1.jpg" }) {
            ...fluidImage960
        }

        ania2: file(relativePath: { eq: "restart/ania2.jpg" }) {
            ...fluidImage960
        }

        ania3: file(relativePath: { eq: "restart/ania3.jpg" }) {
            ...fluidImage960
        }

        ania4: file(relativePath: { eq: "restart/ania4.jpg" }) {
            ...fluidImage960
        }

        ania5: file(relativePath: { eq: "lifetime/ania5.png" }) {
            ...fluidImage960
        }

        ania6: file(relativePath: { eq: "lifetime/ania2.png" }) {
            ...fluidImage960
        }

        restart2Bg: file(relativePath: { eq: "restart/restart2-bg.jpg" }) {
            ...fluidImage1920
        }

        fourDiet: file(relativePath: { eq: "restart/four-diet.png" }) {
            ...fluidImage480
        }

        biggerBase: file(relativePath: { eq: "restart/bigger-base.png" }) {
            ...fluidImage480
        }

        newPrograms: file(relativePath: { eq: "restart/new-programs.png" }) {
            ...fluidImage480
        }

        people1: file(relativePath: { eq: "restart/people/people1.jpg" }) {
            ...fluidImage480
        }

        people2: file(relativePath: { eq: "restart/people/people2.jpg" }) {
            ...fluidImage480
        }

        people3: file(relativePath: { eq: "restart/people/people3.jpg" }) {
            ...fluidImage480
        }

        people4: file(relativePath: { eq: "restart/people/people4.jpg" }) {
            ...fluidImage480
        }

        people5: file(relativePath: { eq: "restart/people/people5.jpg" }) {
            ...fluidImage480
        }

        people6: file(relativePath: { eq: "restart/people/people6.jpg" }) {
            ...fluidImage480
        }

        people7: file(relativePath: { eq: "restart/people/people7.jpg" }) {
            ...fluidImage480
        }

        people8: file(relativePath: { eq: "restart/people/people8.jpg" }) {
            ...fluidImage480
        }

        people9: file(relativePath: { eq: "restart/people/people9.jpg" }) {
            ...fluidImage480
        }

        people10: file(relativePath: { eq: "restart/people/people10.jpg" }) {
            ...fluidImage480
        }

        people11: file(relativePath: { eq: "restart/people/people11.jpg" }) {
            ...fluidImage480
        }

        people12: file(relativePath: { eq: "restart/people/people12.jpg" }) {
            ...fluidImage480
        }

        people13: file(relativePath: { eq: "restart/people/people13.jpg" }) {
            ...fluidImage480
        }

        people14: file(relativePath: { eq: "restart/people/people14.jpg" }) {
            ...fluidImage480
        }

        people15: file(relativePath: { eq: "restart/people/people15.jpg" }) {
            ...fluidImage480
        }

        people16: file(relativePath: { eq: "restart/people/people16.jpg" }) {
            ...fluidImage480
        }

        people17: file(relativePath: { eq: "restart/people/people17.jpg" }) {
            ...fluidImage480
        }

        people18: file(relativePath: { eq: "restart/people/people18.jpg" }) {
            ...fluidImage480
        }

        people19: file(relativePath: { eq: "restart/people/people19.jpg" }) {
            ...fluidImage480
        }

        people20: file(relativePath: { eq: "restart/people/people20.jpg" }) {
            ...fluidImage480
        }

        people21: file(relativePath: { eq: "restart/people/people21.jpg" }) {
            ...fluidImage480
        }

        people22: file(relativePath: { eq: "restart/people/people22.jpg" }) {
            ...fluidImage480
        }

        people23: file(relativePath: { eq: "restart/people/people23.jpg" }) {
            ...fluidImage480
        }

        people24: file(relativePath: { eq: "restart/people/people24.jpg" }) {
            ...fluidImage480
        }

        people25: file(relativePath: { eq: "restart/people/people25.jpg" }) {
            ...fluidImage480
        }

        people26: file(relativePath: { eq: "restart/people/people26.jpg" }) {
            ...fluidImage480
        }

        people27: file(relativePath: { eq: "restart/people/people27.jpg" }) {
            ...fluidImage480
        }

        people28: file(relativePath: { eq: "restart/people/people28.jpg" }) {
            ...fluidImage480
        }

        socialProof1: file(relativePath: { eq: "try/social-proof/social-proof-1.jpg" }) {
            ...fluidImage480
        }

        socialProof2: file(relativePath: { eq: "try/social-proof/social-proof-2.jpg" }) {
            ...fluidImage480
        }

        socialProof3: file(relativePath: { eq: "exclusive-renewal/social-proof/social-proof-3.jpg" }) {
            ...fluidImage480
        }

        socialProof4: file(relativePath: { eq: "exclusive-renewal/social-proof/social-proof-4.jpg" }) {
            ...fluidImage480
        }

        socialProof5: file(relativePath: { eq: "try/social-proof/social-proof-5.jpg" }) {
            ...fluidImage480
        }

        socialProof6: file(relativePath: { eq: "try/social-proof/social-proof-6.jpg" }) {
            ...fluidImage480
        }

        socialProof7: file(relativePath: { eq: "exclusive-renewal/social-proof/social-proof-7.jpg" }) {
            ...fluidImage480
        }

        socialProof8: file(relativePath: { eq: "try/social-proof/social-proof-8.jpg" }) {
            ...fluidImage480
        }

        socialProof9: file(relativePath: { eq: "try/social-proof/social-proof-9.jpg" }) {
            ...fluidImage480
        }

        socialProof10: file(relativePath: { eq: "try/social-proof/social-proof-10.jpg" }) {
            ...fluidImage480
        }

        socialProof11: file(relativePath: { eq: "try/social-proof/social-proof-11.jpg" }) {
            ...fluidImage480
        }

        socialProof12: file(relativePath: { eq: "exclusive-renewal/social-proof/social-proof-12.jpg" }) {
            ...fluidImage480
        }

        sign: file(relativePath: { eq: "restart/sign.png" }) {
            ...fixedImage176
        }

        programs: file(relativePath: { eq: "restart/programs.gif" }) {
            ...fluidImage480
        }

        phone: file(relativePath: { eq: "trainings/phone.gif" }) {
            ...fluidImage480
        }

    }
`;
